#root, .Blank {
	width: 100vW;
	height: 100vH;
}

.Blank > img {
	margin: 5%;
	max-width: 20%;
	max-height: 20%;
}

.Blank > p {
	text-align: center;
	max-width: 400px;
}