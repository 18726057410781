.Frontpage {
	width: 100vw;
	height: 100vh;
}

.Frontpage a {
	color: #f8c069;
}

.Frontpage .BatchSelect select {
	/* appearance: none;
	-webkit-appearance: none; */
	margin: 26px 0 0 100px;
	height: 48px;
	font-size: 18px;
	font-family: Montserrat;
	font-weight: bold;
	padding: 0 15px;
	border: none;
	border-radius: 8px;
	background-color: #f8c069;
}

.Frontpage .TeamInfo,
.Frontpage .DownloadArea {
	color: #f8c069;
	margin: 25px;
	font-weight: bold;
	font-size: 30px;
}

.Frontpage .TeamInfo label,
.Frontpage .DownloadArea label {
	display: block;
	font-size: 15px;
	text-transform: uppercase;
}

.Frontpage .DownloadArea button {
	background-color: transparent;
	border: 2px solid #f8c069;
	border-radius: 8px;
	color: #f8c069;
	padding: 3px 10px;
	font-size: 21px;
	font-family: inherit;
	font-weight: inherit;
	cursor: pointer;
}
.Frontpage .DownloadArea button:hover {
	background-color: rgba(248, 192, 105, 0.2);
}

.Frontpage .DownloadArea button.link {
	appearance: none;
	background-color: inherit;
	border: inherit;
	color: inherit;
	padding: inherit;
	font-size: inherit;
	font-family: inherit;
	font-weight: inherit;
	text-decoration: underline;
	cursor: pointer;
}
.Frontpage .DownloadArea button.link.in-progress {
	text-decoration: none;
}

/* by https://loading.io/css/ */
.Frontpage .loader {
	display: inline-block;
	position: relative;
	width: 30px;
	height: 30px;
}
.Frontpage .loader div {
	box-sizing: border-box;
	display: block;
	position: absolute;
	width: 22px;
	height: 22px;
	margin: 5px;
	border: 5px solid #f8c069;
	border-radius: 50%;
	animation: spin 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	border-color: #f8c069 transparent transparent transparent;
}
.Frontpage .loader div:nth-child(1) {
	animation-delay: -0.45s;
}
.Frontpage .loader div:nth-child(2) {
	animation-delay: -0.3s;
}
.Frontpage .loader div:nth-child(3) {
	animation-delay: -0.15s;
}
.Frontpage .DownloadArea button .loader {
	width: 20px;
	height: 20px;
}
.Frontpage .DownloadArea button .loader div {
	width: 16px;
	height: 16px;
	margin: 2px;
	border-width: 3px;
}

.Frontpage .social-preview {
	pointer-events: none;
	position: absolute;
	bottom: 55px;
	right: 0;
	transition: opacity .3s;
}
.Frontpage .social-preview.hide {
	opacity: 0;
}
.Frontpage .social-preview.show {
	opacity: 1;
}

.Frontpage .social-preview img {
	width: 300px;
	border: 2px solid #f8c069;
	border-radius: 5px;
}


@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
