@font-face {
  font-family: "Sharp Sans";
  font-weight: normal;
  src:
    local("Sharp Sans"),
    url("/assets/fonts/SharpSans-Medium.woff2") format("woff2");
}

@font-face {
  font-family: "Sharp Sans";
  font-weight: bold;
  src:
    local("Sharp Sans"),
    url("/assets/fonts/SharpSans-Extrabold.woff2") format("woff2");
}

html,
body {
  padding: 0;
  margin: 0;
  top: 0;
  position: absolute;
}

body {
  min-width: 100vw;
  min-height: 100vh;
  background-color: black;
  color: white;
  font-family: "Sharp Sans";
  line-height: 140%;
}

h1,
h2,
h3,
h4 {
  margin-top: 0;
}

button {
  font-family: "Sharp Sans";
}

table {
  border-collapse: collapse;
}

.col {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
}

.center {
  align-items: center;
}

.align-end {
  align-items: flex-end;
}

.space-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.grow {
  flex-grow: 1;
}

.wrap {
  flex-wrap: wrap;
}

#logo {
  width: 120px;
  position: absolute;
  top: 0;
  right: 0;
}

.Overlay {
  position: absolute;
}
.Overlay.top {
  top: 0;
}
.Overlay.bottom {
  bottom: 0;
}
.Overlay.left {
  left: 0;
}
.Overlay.right {
  right: 0;
  text-align: right;
}
.Overlay.center {
  width: 100vw;
  display: flex;
  justify-content: center;
}

.Overlay input.hidden {
  display: none;
}

.Logout {
  width: 50px;
  height: 50px;
  margin: 25px;
  padding: 10px;
  border: none;
  border-radius: 25px;
  background-color: #f8c069;
  cursor: pointer;
}

.Logout:hover {
  opacity: 0.8;
}

.Logout img {
  cursor: inherit;
  width: 100%;
  height: 100%;
}

.small {
  font-size: 85%;
}

.smallprint {
  font-size: 75%;
}

@media only screen and (max-device-width: 1199px) {
  .hide-on-mobile {
    display: none;
  }
}
