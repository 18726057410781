/* setup tooltips */
.Tooltip {
	position: relative;
	display: inline-block;
}
.Tooltip:before,
.Tooltip:after {
	display: block;
	opacity: 0;
	pointer-events: none;
	position: absolute;

	transform: translate3d(0, -10px, 0);
	transition: all 0.15s ease-in-out;
}
.Tooltip:after {
	border-right: 6px solid transparent;
	border-bottom: 6px solid rgba(0, 0, 0, 0.75);
	border-left: 6px solid transparent;
	content: "";
	height: 0;
	top: 20px;
	left: 3px;
	width: 0;
}
.Tooltip:before {
	background: rgba(0, 0, 0, 0.75);
	border-radius: 2px;
	color: #fff;
	content: attr(data-title);
	font-size: 14px;
	padding: 6px 10px;
	top: 26px;
	left: -20px;
	max-width: 300px;
	min-width: 200px;
}

.Tooltip:hover:after,
.Tooltip:hover:before {
	opacity: 1;
	transform: translate3d(0, 0, 0);
}
