.Login {
	width: 100vw;
	height: 100vh;
}

.Login > * {
	margin-bottom: 70px;
}

.Login button {
	width: 240px;
	background-color: #f8c069;
	color: black;

	font-weight: bold;

	border: none;
	padding: 12px 20px;

	text-transform: uppercase;
	cursor: pointer;
}

.Login button:hover {
  opacity: 0.8;
}
