#root {
	background-color: #202020;
}

.Certificate {
	color: white;
}

.Certificate canvas {
	width: 100vw;
	height: 100vh;
}

.BottomLine {
	width: 100vW;
	display: flex;
	justify-content: center;
}

.BottomLine button {
	width: 320px;
	display: block;
	padding: 12px 20px;
	background-color: #f8c069;
	font-size: 20px;
	color: black;

	border: none;
	text-decoration: none;

	transition-property: opacity,transform;
	transition-duration: 1s;

	cursor: pointer;
}
.BottomLine button:hover {
	opacity: 0.8;
}
.BottomLine button:active {
}
.BottomLine button.hidden {
	opacity: 0;
	pointer-events: none;
	transform: translateY(100px);
}



.SoundSetting {
	width: 50px;
	height: 50px;
	margin: 25px;
	padding: 10px;
	border: none;
	border-radius: 25px;
	background-color: #f8c069;
	cursor: pointer;
}

.SoundSetting:hover {
	opacity: 0.8;
}

.SoundSetting img {
	cursor: inherit;
	width: 100%;
	height: 100%;
}