.Admin {
	--primary-color: #5822c3;
	--highlight-color: #ff8c1a;

	min-height: 100vh;
	font-family: Montserrat, sans-serif;
	font-style: normal;
	padding: 0.9em 1.2em;

	background-color: var(--primary-color);
	color: white;
}

.Admin a {
	color: white;
}

.Admin img {
	max-width: 100%;
}

.Admin button, .Admin a.button {
	background: transparent;
	border: 1px solid white;
	color: white;
	padding: 4px 8px;
	cursor: pointer;
	text-decoration: none;
	font-size: 90%;
}

.Admin button:hover, .Admin a.button:hover {
	background: rgba(255, 255, 255, 0.3);
}

.Admin label span {
	display: inline-block;
}

.Admin label span + input {
	margin-left: 10px;
}

.Admin input.hidden {
	display: none;
}

.Admin th {
	text-align: left;
}

.Admin .list {
	padding-right: 1em;
}

.Admin .list + .list {
	padding-left: 1em;
	border-left: 1px solid white;
}

.Admin .list a {
	text-decoration: none;
	padding: 0.2em 0;
}

.Admin .list a.active {
	font-weight: bold;
	letter-spacing: 0.02em;
	background-color: rgba(255, 255, 255, 0.2);
}

.Admin .list a:hover {
	background-color: rgba(255, 255, 255, 0.3);
}

.Admin .hover-highlight:hover {
	background-color: rgba(255, 255, 255, 0.2);
}

.Admin .Filter {
	padding-bottom: 0.5em;
}

.Admin .Filter button,
.Admin .Filter a.button {
	border-radius: 16px;
}

.Admin .Filter button:hover,
.Admin .Filter a:hover {
	text-decoration: underline;
}

.Admin .Filter button + button,
.Admin .Filter a + a {
	margin-left: 4px;
}

.Admin .Filter button.active,
.Admin .Filter a.active {
	background: rgba(255, 255, 255, 0.3);
}

.Admin .Participant,
.Admin .Team {
	padding-left: 1em;
	border-left: 1px solid white;
}

.Admin .Participant .portrait {
	width: 50px;
	height: 50px;
	margin-left: 20px;
	background-color: rgba(255, 255, 255, 0.15);
	background-size: cover;
	background-position: center;
	border-radius: 50%;
	border: 2px solid white;
	cursor: pointer;
}
.Admin .Participant .portrait:hover {
	background-color: rgba(255, 255, 255, 0.25);
}

.Admin .Participant .portrait.loading {
	border-style: dashed;
	animation-name: spin;
	animation-duration: 8000ms;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
}

.Admin .Import table {
	font-size: 14px;
}

.Admin .Import table tr {
	border-top: 1px solid #9167e4;
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
