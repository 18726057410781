.Navbar {
  --primary-color: #5822c3;
  --highlight-color: #ff8c1a;

  padding: 0.9em 1.2em;
  width: 100vw;

  background-color: var(--primary-color);
  color: white;

  font-family: Montserrat, sans-serif;
  font-style: normal;
}

.Navbar a {
  color: white;
}

.Navbar > *:not(:last-child) {
  margin-right: 1em;
}

.Navbar header > *:not(:last-child) {
  margin-right: 1em;
}

.User > *:not(:last-child) {
  margin-right: 1em;
}

.Avatar img {
  border: 2px solid white;
  border-radius: 50%;
}

.SearchItem .Tag {
  background-color: var(--primary-color);
  color: white;
  border-radius: 15px;
  padding: 1px 7px;
  margin-top: 1px;
}

.SearchItem .Tag + .Tag {
  margin-left: 3px;
}
