.CSVDropZone {
	border: 1px dashed white;
	padding: 8px 16px;
	border-radius: 16px;
	text-align: center;
	margin-bottom: 8px;
	min-height: 80px;
	background-color: rgba(0, 0, 0, 0.15);
}
.CSVDropZone:hover {
	cursor: pointer;
	text-decoration: underline;	
}

.CSVDropZone.hover {
	background-color: rgba(255, 255, 255, 0.15);
}
